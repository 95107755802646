// Only one item MUST have the "default: true" key

module.exports = {
    en: {
      default: true,
      path: `en`,
      locale: `en-US`,
      dateFormat: `DD/MM/YYYY`,
      siteLanguage: `en`,
      ogLanguage: `en_US`,
      defaultTitle: `Using i18n with Gatsby`,
      defaultDescription: `Gatsby example site using MDX and dependency-free i18n`,
    },
    zh: {
      path: `zh`,
      locale: `zh-TW`,
      dateFormat: `DD.MM.YYYY`,
      siteLanguage: `zh`,
      ogLanguage: `zh_TW`,
      defaultTitle: `Defaut title in chinese`,
      defaultDescription: `Defaut description in chinese`,
    },
  }