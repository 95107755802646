import * as React from "react"
import { Link } from "gatsby"
import LocalizedLink from "../components/localizedLink"
import useTranslations from "../components/useTranslations"
import * as Logo from '../components/svg/lsfHeaderLogo'
import { myContext } from '../../provider'
import NavLink  from '../components/nav-link'
import styled from 'styled-components'

const MenuIcon = styled.button`
  position: fixed;
  top: 2rem;
  right: 2rem;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  cursor: pointer;
  z-index: 25;

  div {
	width: 1.5rem;
	height: 0.1rem;
	background: ${ props => ( props.nav? 'white' : '#a76363')};
	border-radius: 5px;
	transform-origin: 1px;
	position: relative;
	transition: opacity 300ms, transform 300ms;
		
	:first-child {
		transform: ${props => ( props.nav ? 'rotate(45deg)' : 'rotate(0deg)')};
	}

	:nth-child(2) {
		opacity: ${props => ( props.nav ? '0' : '1')};
	}

	:nth-child(3) {
		transform: ${props => ( props.nav ? 'rotate(-45deg)' : 'rotate(0)')};
	}

  }
`

const MenuLinks = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: 100;

	height: 100vh;
	width: 100%;
	background: #44246A;

	background-attachment: fixed;
	position: fixed;
	z-index: 15;
	top: 0;
	right: 0;
	transition: transform 300ms;
	/* transform: translateX(100%); */
	transform: ${({ nav }) => (nav ? "translateX(0)" : "translateX(100%)")};
	overflow-x: hidden; 

	ul {
		list-style-type: none;
	}

	li {
		margin-top: 1rem;
	}
	a {
		text-decoration: none;
		color: white;
		font-size: 1.5rem;
		transition: color 300ms;

		:hover {
			color: #6034ff;
		}
	}
`

// const HeaderContainer = styled.div`
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	z-index: 20;
// 	background: #222;
// 	border-bottom: 1px solid #a76363;
// 	height: 86px;
// 	padding: 24px 0;
// 	width: 100%;
// `



const Header = ({ data }) => {

	// Translation
	const { explore, party, play, the_app, tickets } = useTranslations();

    return (
		<myContext.Consumer>
		{		  
		  context => (	
			  
			  <div>
				  {/* <Ruler /> */}
				  <header>
					  <div className="container mx-auto flex-row md:flex">
						  <button className="flex-1 hidden md:block"> {/* Desktop */}
							  <NavLink to="/"><Logo.LsfHeaderLogo width='180' /> </NavLink>
						  </button>
						  <button className="flex-1 md:hidden"> {/* Mobile */}
							  <NavLink to="/"><Logo.LsfHeaderLogo width="180"/></NavLink>
						  </button>
								  
						  <nav className='flex-row space-x-6 uppercase hidden md:flex'>
							  <button><NavLink to='/'>{explore}</NavLink></button>
							  <button><NavLink to='/party'>{party}</NavLink></button>
							  <button><NavLink to='/play'>{play}</NavLink></button>
							  {/* <button><NavLink to='/articles'>Blog</NavLink></button> */}
							  {/* <button><NavLink to='/playground/lightbox'>Shhh</NavLink></button> */}
							  <button><a className='uppercase font-light hover:text-white' href="https://lifestylefever.com" target="_blank" rel="noreferrer">{the_app}</a></button>
							  <button><a className='uppercase font-light hover:text-white' href="https://nft.lifestylefever.com" target="_blank" rel="noreferrer">{tickets}</a></button>
							  
						  </nav>
					  </div>
				  </header>
  
				  {/* Mobile menu */}
				  <MenuIcon
					  className={ context.nav? 'hambergerWhite flex md:hidden' : 'hambergerBlack flex md:hidden' }
					  onClick={ () => context.changeNav() }
					  nav = { context.nav }
				  >
					  <div />
					  <div />
					  <div />
				  </MenuIcon>
				  <MenuLinks
					  className={ context.nav ? 'slideIn' : 'slideOut' }
					  nav={ context.nav }
				  >
					  <ul className='uppercase'>
						  <li><LocalizedLink onClick={ () => context.changeNav() } to="/">{explore}</LocalizedLink></li>
						  <li><LocalizedLink onClick={ () => context.changeNav() } to="/party">{party}</LocalizedLink></li>
						  <li><LocalizedLink onClick={ () => context.changeNav() } to="/play">{play}</LocalizedLink></li>
						  {/* <li><Link onClick={ () => context.changeNav() } to="/articles">lsf blog</Link></li> */}
						  <li><a href="https://lifestylefever.com" target="_blank" rel="noreferrer" onClick={ () => context.changeNav() }>{the_app}</a></li>
						  <li><a href="https://nft.lifestylefever.com" target="_blank" rel="noreferrer" onClick={ () => context.changeNav() }>{tickets}</a></li>
						  <li><Link onClick={ () => context.changeNav() } to="/" hrefLang="en">English</Link></li>
						  <li>/</li>
						  <li><Link onClick={ () => context.changeNav() } to="/zh" hrefLang="zh">中文</Link></li>
					  </ul>
				  </MenuLinks>
			  </div>
		  )
		}
	  </myContext.Consumer>
	)
  
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: `Lifestyle Fever - By Swinger for Swingers in the alternative lifestyle`,
// }

export default Header
