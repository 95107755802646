import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleContext } from "./layout"

const useTranslations = () => {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext)
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query)

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    }
  })

  // Only return translations for the current locale
  const { translations } = simplified.filter(lang => lang.name === locale)[0]

  return translations
}

export default useTranslations

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            the_most
            parties
            in
            asia
            bs4s
            explore
            party
            play
            the_app
            tickets
            the_most_exclusive_ss
            everyday_thousands_of_people
            nationally_known
            dont_miss_out
            our_world_famous_events
            exclusive
            upscaled
            classy
            erotic
            open_minded
            whatever_after
            upcoming_events
            past_events
            our_world_famous_events
            parties_in_asia
            we_challenge
            each_lifestyle_fever_party
            meet_your_match
            whether_you_seeking
            new_dating_site
            hello
            subline
            backToHome
            party_title
            party_subtitle
            party_upcoming_event
            party_online
            party_title_fantasy
            party_text_fantasy
          }
        }
      }
    }
  }
`