import * as React from "react"
import { MDXProvider } from "@mdx-js/react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { myContext } from '../../provider'
import Header from "./header"
import Helmet from 'react-helmet'
import MdxLink from "./mdxLink"

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale } }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (

	<LocaleContext.Provider value={{ locale }}>
		<div>
			<Helmet>
				<title>Lifestyle Fever | Empowering Swingers for couples and singles around the world</title>
				<meta name="description" content="The most exclusive secret society in Asia. Build by Swinger for Swingers" />
				<meta name="keywords" content="alternative lifestyle, lifestyle, swingers, couples, dating, sexual freedom, freedom, shamefree" />
				<meta name="lang" content="en" />
				<meta property="og:title" content="Lifestyle Fever | Empowering Swingers for couples and singles around the world" />
				<meta property="og:type" content="website" />
				<meta property="og:description" content="The most exclusive secret society in Asia. Build by Swinger for Swingers" />
				<meta property="og:image" content="../images/OG_image.jpg" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:url" content="https://explore.lifestylefever.com" />
				<link rel="canonical" href="" />
			</Helmet>

			<div className='flex flex-col relative'>
			<myContext.Consumer>
      			{context => (
				<Header siteTitle={data.site.siteMetadata?.title || `Title`} />
				)}
			</myContext.Consumer>
				<MDXProvider components={{ a: MdxLink }}>
				<main className='flex-grow mt-[86px]'>
					{children}
				</main>
				</MDXProvider>


				<footer className="mt-12 py-6 border-t border-secondary">
					<div className='container flex flex-col md:flex-row'>
						<div className='flex-1'>
							<p>Lifestyle Fever &copy; 2022 - 2033</p>
						</div>
						<div className='flex-1'>
							<p>Made with {' '} ❤  by Swinger 4 Swingers</p>
						</div>
						<div className='flex-1'>
							<Link to="/" hrefLang="en">English</Link>
							  /
							<Link to="/zh" hrefLang="zh">中文</Link>
						</div>
					</div>
				</footer>
			

			</div>
      	</div>
	</LocaleContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout, LocaleContext}
