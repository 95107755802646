import "./src/css/global.scss"
import CustomLayout from "./wrapPageElement"
import React from "react"
import Layout from "./src/components/layout"
import Provider from './provider'

import ReactDOM from "react-dom"

// export const wrapPageElement = ({ element, props }) => {
//     return <Layout {...props}>{element}</Layout>
// }

export function replaceHydrateFunction() {
    return (element, container, callback) => {
        ReactDOM.render(element, container, callback)
    }
}

export const wrapPageElement = CustomLayout
export const wrapRootElement = Provider;