import * as React from "react"
import { Link } from 'gatsby'
import LocalizedLink from "../components/localizedLink"

const NavLink = ({ children, to }) => (
    <LocalizedLink to={to} activeClassName="activeLink" className='uppercase font-light hover:text-white'>
        { children }
    </LocalizedLink>
)

export default NavLink;