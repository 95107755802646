import React, { useState } from 'react';

const defaultState = {
    nav: () => {},

}

export const myContext = React.createContext(defaultState);

const Provider = props => {
    const [nav, showNav ] = useState(false);

    return (
        <myContext.Provider value={{ 
            nav, changeNav: () => showNav(!nav)
         }}>
            {props.children}
        </myContext.Provider>
    )
}

const Provide = ({element}) => (
    <Provider>
        { element }
    </Provider>
)

export default Provide

// export default ({ element }) => (
//     <Provider>
//         { element }
//     </Provider>
// )
